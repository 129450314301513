import React, { useState, useEffect } from "react";
import { CardContent } from "@mui/material";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import {
    Container,
    Typography,
    TextField,
    Box,
    IconButton,
    Fade,
    useTheme,
    CircularProgress,
    Checkbox,
    InputAdornment,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Edit as EditIcon, CameraAlt as CameraIcon, Search as SearchIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CropperModal from "./CropperModal";
import { LargeAvatar } from "./StyledComponents";
import { useProfile } from "./useProfile";
import { useImageUpload } from "./useImageUpload";
import { MainPageProps } from "./types";
import { KEYWORDS } from "./keywords";
import { OCCUPATIONS } from "./occupations";
import { FixedSizeList } from "react-window";
import { Chip, Paper, ListItemText, ListItem, ListItemIcon } from "@mui/material";
import { LocalOffer as TagIcon } from "@mui/icons-material";
import { updateUserAttributes } from "@aws-amplify/auth";
import { deleteUser } from "aws-amplify/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { fetchAuthSession } from "aws-amplify/auth";

export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;

const MyDashboard: React.FC<MainPageProps> = ({ signOut, user }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {
        subid,
        bio,
        newBio,
        isLoading,
        isEditing,
        imageUrl,
        setNewBio,
        setIsEditing,
        updateBio,
        trackedKeywords,
        setTrackedKeywords,
        occupation,
        setOccupation,
        newOccupation,
        setNewOccupation,
        lang,
        setLang,
    } = useProfile(user);

    const [checkedKeywords, setCheckedKeywords] = useState<string[]>([]);
    const [keywordFilter, setKeywordFilter] = useState("");
    const [isKeywordEditing, setIsKeywordEditing] = useState(false);

    const {
        file,
        isOpen,
        imgSrc,
        zoom,
        minZoom,
        crop,
        croppedAreaPixels,
        onFileChange,
        setIsOpen,
        setCrop,
        setZoom,
        onCropComplete,
        showCroppedImage,
        onMediaLoaded,
    } = useImageUpload();

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteUser = async () => {
        try {
            await deleteUser();
            signOut();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
        handleCloseDeleteDialog();
    };

    useEffect(() => {
        if (trackedKeywords) {
            setCheckedKeywords(trackedKeywords.split(",").map((keyword) => keyword.trim()));
        }
    }, [trackedKeywords]);

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.name;
        setCheckedKeywords((prev) => (event.target.checked ? [...prev, keyword] : prev.filter((k) => k !== keyword)));
    };

    const handleKeywordToggle = (keyword: string) => {
        const event = {
            target: {
                name: keyword,
                checked: !checkedKeywords.includes(keyword),
            },
        } as React.ChangeEvent<HTMLInputElement>;

        handleKeywordChange(event);
    };

    const saveProfile = async () => {
        const userAttributes = {
            "custom:occupation": newOccupation || "",
            "custom:lang": lang || "",
            "custom:bio": newBio || "",
        };
        await updateUserAttributes({ userAttributes });
        await updateBio();
    };

    const saveKeyword = async () => {
        const userAttributes = {
            "custom:trackedKeywords": checkedKeywords.join(", "),
        };
        await updateUserAttributes({ userAttributes });
        setIsKeywordEditing(!isKeywordEditing);
    };

    const cancelKeyword = async () => {
        setIsKeywordEditing(!isKeywordEditing);
        if (trackedKeywords) {
            setCheckedKeywords(trackedKeywords.split(",").map((keyword) => keyword.trim()));
        }
    };

    const filteredKeywords = KEYWORDS.filter((keyword) => keyword.toLowerCase().includes(keywordFilter.toLowerCase()));

    if (isLoading) {
        return (
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Fade in={true} timeout={1000}>
                <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box position="relative">
                            <LargeAvatar theme={theme}>
                                {imageUrl ? (
                                    <StorageImage alt="profile_image" path={imageUrl} />
                                ) : (
                                    user.username[0].toUpperCase()
                                )}
                            </LargeAvatar>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    backgroundColor: theme.palette.background.paper,
                                    "&:hover": { backgroundColor: theme.palette.grey[200] },
                                }}
                            >
                                <input hidden accept="image/*" type="file" onChange={onFileChange} />
                                <CameraIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="h4" gutterBottom fontWeight="bold">
                            {user.username}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            SubID: {subid}
                        </Typography>
                    </Box>
                    <Box mt={4}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            fontWeight="bold"
                            color="primary"
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            Profile
                            {isEditing ? (
                                <>
                                    <IconButton onClick={saveProfile} sx={{ ml: 1, p: 0, height: "auto" }}>
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => setIsEditing(false)}
                                        sx={{ ml: 1, p: 0, height: "auto" }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton onClick={() => setIsEditing(true)} sx={{ ml: 1, p: 0, height: "auto" }}>
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Typography>
                        {isEditing ? (
                            <Box component="form" noValidate autoComplete="off">
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="occupation-select-label">Career</InputLabel>
                                    <Select
                                        labelId="occupation-select-label"
                                        value={newOccupation}
                                        onChange={(e) => setNewOccupation(e.target.value)}
                                        label="Career"
                                    >
                                        {OCCUPATIONS.map((occ) => (
                                            <MenuItem key={occ} value={occ}>
                                                {occ}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={newBio}
                                    onChange={(e) => setNewBio(e.target.value)}
                                    placeholder="Please update the bio"
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="language-select-label">Language</InputLabel>
                                    <Select
                                        labelId="language-select-label"
                                        value={lang}
                                        onChange={(e) => setLang(e.target.value)}
                                        label="Language"
                                    >
                                        <MenuItem value="EN">EN</MenuItem>
                                        <MenuItem value="JA">JA</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : (
                            <>
                                <Typography variant="body1" paragraph>
                                    <strong>Career</strong>
                                    <br />
                                    {occupation || "Not specified"}
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <strong>Bio</strong>
                                    <br />
                                    {bio || "There is no bio yet."}
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <strong>Language: {lang}</strong>
                                </Typography>
                            </>
                        )}
                    </Box>

                    {/* Tracked Keywords section */}
                    <Box mt={4}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            fontWeight="bold"
                            color="primary"
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            Tracked keywords
                            {isKeywordEditing ? (
                                <>
                                    <IconButton onClick={saveKeyword} sx={{ ml: 1, p: 0, height: "auto" }}>
                                        <CheckIcon />
                                    </IconButton>

                                    <IconButton onClick={cancelKeyword} sx={{ ml: 1, p: 0, height: "auto" }}>
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton
                                    onClick={() => setIsKeywordEditing(!isKeywordEditing)}
                                    sx={{ ml: 1, p: 0, height: "auto" }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Typography>

                        {checkedKeywords.length > 0 ? (
                            <Paper
                                sx={{
                                    p: 2,
                                    mb: 3,
                                    borderRadius: 2,
                                    backgroundColor: theme.palette.background.default,
                                    boxShadow: "none", // 影を取り除く
                                    border: "none", // 境界線を取り除く
                                }}
                            >
                                <Box display="flex" flexWrap="wrap" gap={1}>
                                    {checkedKeywords.map((keyword, index) => (
                                        <Chip
                                            key={index}
                                            label={keyword}
                                            onDelete={isKeywordEditing ? () => handleKeywordToggle(keyword) : undefined}
                                            color="primary"
                                            icon={<TagIcon />}
                                        />
                                    ))}
                                </Box>
                            </Paper>
                        ) : (
                            <Typography variant="body1">Not registered</Typography>
                        )}
                        {isKeywordEditing && (
                            <>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="キーワードを検索..."
                                    value={keywordFilter}
                                    onChange={(e) => setKeywordFilter(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ mb: 2 }}
                                />

                                <Paper elevation={3} sx={{ height: 300, overflow: "hidden", borderRadius: 2 }}>
                                    <FixedSizeList
                                        height={300}
                                        itemCount={filteredKeywords.length}
                                        itemSize={46}
                                        width="100%"
                                        overscanCount={5}
                                    >
                                        {({ index, style }) => (
                                            <ListItem
                                                style={style}
                                                key={index}
                                                dense
                                                button
                                                onClick={() => handleKeywordToggle(filteredKeywords[index])}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checkedKeywords.includes(filteredKeywords[index])}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={filteredKeywords[index]} />
                                            </ListItem>
                                        )}
                                    </FixedSizeList>
                                </Paper>
                            </>
                        )}
                    </Box>

                    {/* Sign out button */}
                    <Box mt={6} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={signOut}
                            startIcon={<ExitToAppIcon />}
                            size="large"
                        >
                            Sign out
                        </Button>
                    </Box>
                    <Box mt={6} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleOpenDeleteDialog}
                            startIcon={<DeleteIcon />}
                            size="large"
                        >
                            Delete Account
                        </Button>
                    </Box>
                    <Dialog
                        open={openDeleteDialog}
                        onClose={handleCloseDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete Account"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete your account? This action cannot be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteUser} color="error" autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Fade>

            <CropperModal
                crop={crop}
                setCrop={setCrop}
                zoom={zoom}
                setZoom={setZoom}
                onCropComplete={onCropComplete}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                imgSrc={imgSrc}
                showCroppedImage={showCroppedImage}
                onMediaLoaded={onMediaLoaded}
                minZoom={minZoom}
            />
        </Container>
    );
};

export default withAuthenticator(MyDashboard);
