import React from "react";
import { Box, Typography } from "@mui/material";

interface DeviceDetailsBoxProps {
    Device: any;
    DeviceData: any;
}

const DeviceProfile: React.FC<DeviceDetailsBoxProps> = ({ Device, DeviceData }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "16px",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px",
            }}
        >
            {/* コンテンツ */}
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "12px",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "bold",
                        color: "primary.main",
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <a href={Device.official_site} target="_blank" rel="noopener noreferrer">
                        {Device.name}
                    </a>
                </Typography>
                {DeviceData && (
                    <Typography
                        variant="body1"
                        sx={{
                            color: "text.secondary",
                            maxWidth: "60ch",
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        {DeviceData.device.desc}
                    </Typography>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                    }}
                >
                    {DeviceData &&
                        DeviceData.recent_topics.slice(0, 6).map((topic: string, index: number) => (
                            <Box
                                key={index}
                                sx={{
                                    padding: "6px 12px",
                                    backgroundColor: "secondary.light",
                                    color: "secondary.contrastText",
                                    borderRadius: "16px",
                                    fontSize: "14px",
                                    fontWeight: "medium",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                #{topic}
                            </Box>
                        ))}
                </Box>
                <Box
                    sx={{
                        marginTop: "12px", // ボタンを要素の下部に配置
                    }}
                >
                    <a
                        href={Device.official_site}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "inline-block",
                            padding: "10px 20px",
                            backgroundColor: "#1976d2", // primary.main に合わせて調整可能
                            color: "#fff",
                            textDecoration: "none",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        Official Site
                    </a>
                </Box>
            </Box>
            {/* 画像 */}
            <Box
                sx={{
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: { xs: "100%", md: "40%" },
                }}
            >
                <a href={Device.official_site} target="_blank" rel="noopener noreferrer">
                    <img
                        src={Device.image}
                        alt={Device.name}
                        style={{
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                    />
                </a>
            </Box>
        </Box>
    );
};

export default DeviceProfile;
