import React, { useState, useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import {
    Container,
    useTheme,
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    List,
    ListItem,
    Box,
    Tabs,
    Tab,
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress,
    Button,
} from "@mui/material";
import { MainPageProps } from "./types";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { updateUserAttributes, fetchUserAttributes } from "@aws-amplify/auth";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IconButton } from "@mui/material";

import dashBoardRegularPapersPresenter from "../../backend/presenters/dashBoardRegularPapersPresenter";
import dashBoardUserPapersPresenter from "../../backend/presenters/dashBoardUserPapersPresenter";
import dashBoardLLMSummarizedPaperPresenter from "../../backend/presenters/dashBoardLLMSummarizedPaperPresenter";
import regularPaper from "../../dataclass/regularPaper";
import LLMSummarizedPaperWithJournal from "../../dataclass/LLMSummarizedPaperWithJournal";
import EmptyPapersMessage from "../molecules/EmptyPapersMessage";

const MyDashboard: React.FC<MainPageProps> = ({ signOut, user }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [regularPapers, setRegularPapers] = useState<regularPaper[]>([]);
    const [favoritePapers, setFavoritePapers] = useState<regularPaper[]>([]);
    const [bookmarkPapers, setBookmarkPapers] = useState<regularPaper[]>([]);
    const [checkedPapers, setCheckedPapers] = useState<regularPaper[]>([]);
    const [LLMSummarizedPaperWithJournal, setLLMSummarizedPaperWithJournal] = useState<LLMSummarizedPaperWithJournal[]>(
        []
    );
    const [tabValue, setTabValue] = useState(0);
    const [language, setLanguage] = useState<string>("EN");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPapers = async () => {
            setIsLoading(true);
            try {
                const Papers: regularPaper[] = await dashBoardRegularPapersPresenter();
                const FavoritePapers: regularPaper[] = await dashBoardUserPapersPresenter("favorite");
                const BookmarkPapers: regularPaper[] = await dashBoardUserPapersPresenter("bookmark"); // 追加
                const CheckedPapers: regularPaper[] = await dashBoardUserPapersPresenter("checked"); // 追加
                const LLMmockPapers: LLMSummarizedPaperWithJournal[] = await dashBoardLLMSummarizedPaperPresenter();
                setRegularPapers(Papers);
                setFavoritePapers(FavoritePapers);
                setBookmarkPapers(BookmarkPapers); // 追加
                setCheckedPapers(CheckedPapers); // 追加
                setLLMSummarizedPaperWithJournal(LLMmockPapers);
                fetchUserPreferences();
            } catch (error) {
                console.error("Error fetching papers:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPapers();
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string) => {
        if (newLanguage !== null) {
            setLanguage(newLanguage);
        }
    };

    // 3つの状態を管理するための状態変数
    const [favorites, setFavorites] = useState<number[]>([]);
    const [bookmarks, setBookmarks] = useState<number[]>([]);
    const [checkeds, setCheckeds] = useState<number[]>([]);
    const [bookmarkShowChecked, setBookmarkShowChecked] = useState(false);

    const fetchUserPreferences = async () => {
        try {
            const attributes = await fetchUserAttributes();
            const favoritePapers = attributes["custom:favorite_papers"];
            const bookmarkedPapers = attributes["custom:bookmark_papers"];
            const checkedPapers = attributes["custom:checked_papers"];

            setFavorites(favoritePapers ? favoritePapers.split(",").map(Number) : []);
            setBookmarks(bookmarkedPapers ? bookmarkedPapers.split(",").map(Number) : []);
            setCheckeds(checkedPapers ? checkedPapers.split(",").map(Number) : []);
        } catch (error) {
            // console.error("Error fetching user preferences:", error);
        }
    };

    // 汎用的な状態更新関数
    const togglePaperState = async (
        paperID: number,
        currentState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        attributeName: string
    ) => {
        const updatedState = currentState.includes(paperID)
            ? currentState.filter((id) => id !== paperID)
            : [...currentState, paperID];

        setState(updatedState);

        try {
            const userAttributes = {
                [attributeName]: updatedState.join(","),
            };
            await updateUserAttributes({ userAttributes });
        } catch (error) {
            console.error(`Error updating ${attributeName}:`, error);
            setState(currentState);
        }
    };

    // 各アクションのトグル関数
    const toggleFavorite = (paperID: number) =>
        togglePaperState(paperID, favorites, setFavorites, "custom:favorite_papers");

    const toggleBookmark = (paperID: number) =>
        togglePaperState(paperID, bookmarks, setBookmarks, "custom:bookmark_papers");

    const toggleChecked = (paperID: number) =>
        togglePaperState(paperID, checkeds, setCheckeds, "custom:checked_papers");

    const renderPapers = () => {
        if (isLoading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                </Box>
            );
        }

        if (tabValue === 2) {
            return (
                <List sx={{ padding: 0 }}>
                    {regularPapers.length > 0 ? (
                        regularPapers.map((Paper, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    borderBottom:
                                        index !== regularPapers.length - 1
                                            ? `1px solid ${theme.palette.divider}`
                                            : "none",
                                    marginBottom: "-2px",
                                }}
                            >
                                <Card
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        p: 2,
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    src={Paper.favicon}
                                                    alt={"Paper.author"}
                                                    sx={{ width: 48, height: 48 }}
                                                />
                                            }
                                            title={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {Paper.title}
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{ ml: 0.5 }}
                                                    >
                                                        {Paper.year}
                                                    </Typography>
                                                </Typography>
                                            }
                                            sx={{ pb: 1 }}
                                        />
                                    </a>
                                    <CardContent sx={{ pt: 0 }}>
                                        <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body1" color="text.primary">
                                                {Paper.abstract}
                                            </Typography>
                                        </a>
                                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                            <IconButton onClick={() => toggleFavorite(Paper.id)}>
                                                {favorites.includes(Paper.id) ? (
                                                    <StarIcon color="warning" />
                                                ) : (
                                                    <StarBorderIcon />
                                                )}
                                            </IconButton>
                                            <IconButton onClick={() => toggleBookmark(Paper.id)}>
                                                {bookmarks.includes(Paper.id) ? (
                                                    <BookmarkIcon color="primary" />
                                                ) : (
                                                    <BookmarkBorderIcon />
                                                )}
                                            </IconButton>
                                            <Box sx={{ marginLeft: "auto" }}>
                                                <IconButton onClick={() => toggleChecked(Paper.id)}>
                                                    {checkeds.includes(Paper.id) ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <CheckCircleOutlineIcon />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))
                    ) : (
                        <EmptyPapersMessage />
                    )}
                </List>
            );
        } else if (tabValue === 1) {
            const currentPapers = LLMSummarizedPaperWithJournal.filter(
                (Paper): Paper is LLMSummarizedPaperWithJournal => "language" in Paper && Paper.language === language
            );
            return (
                <List sx={{ padding: 0 }}>
                    {currentPapers.length > 0 ? (
                        currentPapers.map((Paper, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    borderBottom:
                                        index !== LLMSummarizedPaperWithJournal.length - 1
                                            ? `1px solid ${theme.palette.divider}`
                                            : "none",
                                    marginBottom: "-2px",
                                }}
                            >
                                <Card
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        p: 2,
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    <a href={Paper.paper_url} target="_blank" rel="noopener noreferrer">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    src={Paper.image}
                                                    alt={"Paper.author"}
                                                    sx={{ width: 48, height: 48 }}
                                                />
                                            }
                                            title={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {Paper.title}
                                                </Typography>
                                            }
                                            sx={{ pb: 1 }}
                                        />
                                    </a>
                                    <CardContent sx={{ pt: 0 }}>
                                        <a href={Paper.paper_url} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body1" color="text.primary">
                                                {Paper.summary}
                                            </Typography>
                                        </a>
                                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                            <IconButton onClick={() => toggleFavorite(Paper.id)}>
                                                {favorites.includes(Paper.id) ? (
                                                    <StarIcon color="warning" />
                                                ) : (
                                                    <StarBorderIcon />
                                                )}
                                            </IconButton>
                                            <IconButton onClick={() => toggleBookmark(Paper.id)}>
                                                {bookmarks.includes(Paper.id) ? (
                                                    <BookmarkIcon color="primary" />
                                                ) : (
                                                    <BookmarkBorderIcon />
                                                )}
                                            </IconButton>
                                            <Box sx={{ marginLeft: "auto" }}>
                                                <IconButton onClick={() => toggleChecked(Paper.id)}>
                                                    {checkeds.includes(Paper.id) ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <CheckCircleOutlineIcon />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))
                    ) : (
                        <EmptyPapersMessage />
                    )}
                </List>
            );
        } else if (tabValue === 3) {
            // Bookmark Papers
            return (
                <List sx={{ padding: 0 }}>
                    {bookmarkPapers.length > 0 ? (
                        bookmarkPapers
                            .filter((paper) =>
                                bookmarkShowChecked ? checkeds.includes(paper.id) : !checkeds.includes(paper.id)
                            )
                            .map((Paper, index) => (
                                <ListItem
                                    key={index}
                                    disablePadding
                                    sx={{
                                        borderBottom:
                                            index !== bookmarkPapers.length - 1
                                                ? `1px solid ${theme.palette.divider}`
                                                : "none",
                                        marginBottom: "-2px",
                                    }}
                                >
                                    <Card
                                        sx={{
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "8px",
                                            p: 2,
                                            fontFamily: "sans-serif",
                                        }}
                                    >
                                        <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        src={Paper.favicon}
                                                        alt={"Paper.author"}
                                                        sx={{ width: 48, height: 48 }}
                                                    />
                                                }
                                                title={
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {Paper.title}
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{ ml: 0.5 }}
                                                        >
                                                            {Paper.year}
                                                        </Typography>
                                                    </Typography>
                                                }
                                                sx={{ pb: 1 }}
                                            />
                                        </a>
                                        <CardContent sx={{ pt: 0 }}>
                                            <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                                <Typography variant="body1" color="text.primary">
                                                    {Paper.abstract}
                                                </Typography>
                                            </a>
                                            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                                <IconButton onClick={() => toggleFavorite(Paper.id)}>
                                                    {favorites.includes(Paper.id) ? (
                                                        <StarIcon color="warning" />
                                                    ) : (
                                                        <StarBorderIcon />
                                                    )}
                                                </IconButton>
                                                <IconButton onClick={() => toggleBookmark(Paper.id)}>
                                                    {bookmarks.includes(Paper.id) ? (
                                                        <BookmarkIcon color="primary" />
                                                    ) : (
                                                        <BookmarkBorderIcon />
                                                    )}
                                                </IconButton>
                                                <Box sx={{ marginLeft: "auto" }}>
                                                    <IconButton onClick={() => toggleChecked(Paper.id)}>
                                                        {checkeds.includes(Paper.id) ? (
                                                            <CheckCircleIcon color="success" />
                                                        ) : (
                                                            <CheckCircleOutlineIcon />
                                                        )}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            ))
                    ) : (
                        <EmptyPapersMessage />
                    )}
                </List>
            );
        } else if (tabValue === 4) {
            // Checked Papers
            return (
                <List sx={{ padding: 0 }}>
                    {checkedPapers.length > 0 ? (
                        checkedPapers.map((Paper, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    borderBottom:
                                        index !== checkedPapers.length - 1
                                            ? `1px solid ${theme.palette.divider}`
                                            : "none",
                                    marginBottom: "-2px",
                                }}
                            >
                                <Card
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        p: 2,
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    src={Paper.favicon}
                                                    alt={"Paper.author"}
                                                    sx={{ width: 48, height: 48 }}
                                                />
                                            }
                                            title={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {Paper.title}
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{ ml: 0.5 }}
                                                    >
                                                        {Paper.year}
                                                    </Typography>
                                                </Typography>
                                            }
                                            sx={{ pb: 1 }}
                                        />
                                    </a>
                                    <CardContent sx={{ pt: 0 }}>
                                        <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body1" color="text.primary">
                                                {Paper.abstract}
                                            </Typography>
                                        </a>
                                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                            <IconButton onClick={() => toggleFavorite(Paper.id)}>
                                                {favorites.includes(Paper.id) ? (
                                                    <StarIcon color="warning" />
                                                ) : (
                                                    <StarBorderIcon />
                                                )}
                                            </IconButton>
                                            <IconButton onClick={() => toggleBookmark(Paper.id)}>
                                                {bookmarks.includes(Paper.id) ? (
                                                    <BookmarkIcon color="primary" />
                                                ) : (
                                                    <BookmarkBorderIcon />
                                                )}
                                            </IconButton>
                                            <Box sx={{ marginLeft: "auto" }}>
                                                <IconButton onClick={() => toggleChecked(Paper.id)}>
                                                    {checkeds.includes(Paper.id) ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <CheckCircleOutlineIcon />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))
                    ) : (
                        <EmptyPapersMessage />
                    )}
                </List>
            );
        } else {
            return (
                <List sx={{ padding: 0 }}>
                    {favoritePapers.length > 0 ? (
                        favoritePapers.map((Paper, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    borderBottom:
                                        index !== favoritePapers.length - 1
                                            ? `1px solid ${theme.palette.divider}`
                                            : "none",
                                    marginBottom: "-2px",
                                }}
                            >
                                <Card
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        p: 2,
                                        fontFamily: "sans-serif",
                                    }}
                                >
                                    <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    src={Paper.favicon}
                                                    alt={"Paper.author"}
                                                    sx={{ width: 48, height: 48 }}
                                                />
                                            }
                                            title={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {Paper.title}
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{ ml: 0.5 }}
                                                    >
                                                        {Paper.year}
                                                    </Typography>
                                                </Typography>
                                            }
                                            sx={{ pb: 1 }}
                                        />
                                    </a>
                                    <CardContent sx={{ pt: 0 }}>
                                        <a href={Paper.url} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="body1" color="text.primary">
                                                {Paper.abstract}
                                            </Typography>
                                        </a>
                                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                            <IconButton onClick={() => toggleFavorite(Paper.id)}>
                                                {favorites.includes(Paper.id) ? (
                                                    <StarIcon color="warning" />
                                                ) : (
                                                    <StarBorderIcon />
                                                )}
                                            </IconButton>
                                            <IconButton onClick={() => toggleBookmark(Paper.id)}>
                                                {bookmarks.includes(Paper.id) ? (
                                                    <BookmarkIcon color="primary" />
                                                ) : (
                                                    <BookmarkBorderIcon />
                                                )}
                                            </IconButton>
                                            <Box sx={{ marginLeft: "auto" }}>
                                                <IconButton onClick={() => toggleChecked(Paper.id)}>
                                                    {checkeds.includes(Paper.id) ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <CheckCircleOutlineIcon />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))
                    ) : (
                        <EmptyPapersMessage />
                    )}
                </List>
            );
        }
    };

    return (
        <Container maxWidth="md" disableGutters>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Paper tabs"
                    sx={{
                        position: "relative",
                        minHeight: 48,
                        "& .MuiTabs-flexContainer": {
                            height: "100%",
                        },
                    }}
                >
                    <Tab label="Like Papers" sx={{ height: "100%" }} />
                    <Tab label="LLM Papers" sx={{ height: "100%" }} />
                    <Tab label="Regular Papers" sx={{ height: "100%" }} />
                    <Tab label="Bookmark Papers" sx={{ height: "100%" }} />
                    <Tab label="Checked Papers" sx={{ height: "100%" }} />

                    <Box
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: tabValue === 1 ? "block" : "none",
                        }}
                    >
                        <ToggleButtonGroup
                            value={language}
                            exclusive
                            onChange={handleLanguageChange}
                            aria-label="language"
                            size="small"
                        >
                            <ToggleButton value="EN" aria-label="English">
                                EN
                            </ToggleButton>
                            <ToggleButton value="JA" aria-label="Japanese">
                                JA
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: tabValue === 3 ? "block" : "none",
                        }}
                    >
                        <Button onClick={() => setBookmarkShowChecked((prev) => !prev)}>
                            {bookmarkShowChecked ? "Hide Checked" : "Show Checked"}
                        </Button>
                    </Box>
                </Tabs>
            </Box>

            {renderPapers()}
        </Container>
    );
};

export default withAuthenticator(MyDashboard);
