import regularPaper from "../../dataclass/regularPaper";

async function MySerchResult(
    query: string,
    limit: number | null = null,
    offset: number | null = null,
    order: string | null = null
): Promise<regularPaper[]> {
    interface SearchResultItem {
        id: number;
        title: string;
        author: string;
        abstract: string | null;
        url: string;
        favicon: string;
        year: number;
        publisher: string;
        distance: number;
        order: number;
        cited: number;
    }
    const fetchResults = async () => {
        let url = `${process.env.REACT_APP_API_URL}/search2?query=${query}`;
        if (limit !== null) {
            url += `&limit=${limit}`;
        }
        if (offset !== null) {
            url += `&offset=${offset}`;
        }
        if (order !== null) {
            url += `&order=${order}`;
        }
        const response = await fetch(url, {
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                origin: window.location.origin,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const sanitizedText = text.replace(/NaN/g, "null");
        const data = JSON.parse(sanitizedText);
        return data;
    };

    const response = await fetchResults().catch((e) => {
        console.error("Error:", e);
        return [];
    });

    return response.map((item: SearchResultItem) => {
        let replacedAuthor = item.author.replace(/(?<=\w)'(?=\w)/g, "\\'").replace(/'/g, '"');
        let parsedAuthor;
        try {
            parsedAuthor = JSON.parse(replacedAuthor);
        } catch (error) {
            try {
                // TODO: 正規表現
                replacedAuthor = replacedAuthor.replace(/De" Be/g, "De' Be");
                parsedAuthor = JSON.parse(replacedAuthor);
            } catch (error) {
                console.error("Failed to parse replacedAuthor:", error);
                parsedAuthor = JSON.parse(JSON.stringify(["unkown", "author"]));
            }
        }
        return new regularPaper(
            item.id,
            item.title ? String(item.title) : "",
            parsedAuthor,
            item.abstract ? String(item.abstract) : "",
            item.url ? String(item.url) : "",
            item.favicon ? String(item.favicon) : "",
            item.year ? item.year : 0,
            item.publisher ? String(item.publisher) : "",
            item.distance,
            item.order,
            item.cited ? item.cited : 0
        );
    });
}

export default MySerchResult;
