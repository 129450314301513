import React from "react";
import { FileText, ArrowUpRight } from "lucide-react";

interface RecentPapersProps {
    DeviceData: any; // 適切な型に置き換えてください
}

const DeviceRecentPapers: React.FC<RecentPapersProps> = ({ DeviceData }) => {
    return (
        <div style={{ marginTop: "18px" }}>
            <h3 style={{ fontWeight: "bold" }}>Recent Papers</h3>
            <div
                style={{
                    gap: "20px",
                    overflowX: "auto",
                    padding: "10px",
                    scrollSnapType: "x mandatory",
                }}
            >
                {DeviceData &&
                    DeviceData.recent_paper.slice(0, 3).map((paper: any, index: number) => (
                        <div
                            key={paper.id}
                            style={{
                                minWidth: "300px", // カードの最低幅を設定
                                flex: "0 0 auto", // 横方向スクロールをサポート
                            }}
                            className="bg-white p-6 rounded-lg hover:shadow-md transition-shadow"
                        >
                            <a href={paper.url} target="_blank" rel="noopener noreferrer" className="flex items-start">
                                <div className="flex-shrink-0 mr-4">
                                    <div className="w-14 h-14 bg-indigo-100 rounded-full flex items-center justify-center">
                                        <FileText className="w-6 h-6 text-indigo-600" />
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <h4 className="text-lg font-semibold text-indigo-700 hover:text-indigo-900 transition-colors">
                                        {paper.title}
                                    </h4>
                                    <p className="text-sm text-gray-600 mt-1">
                                        {paper.author} ({paper.year})
                                    </p>
                                </div>
                                <div className="flex-shrink-0 ml-4">
                                    <ArrowUpRight className="w-5 h-5 text-gray-400" />
                                </div>
                            </a>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DeviceRecentPapers;
