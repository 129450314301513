import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { FormGroup, FormControl, FormLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

const SortSelector: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sortOrder, setSortOrder] = useState<string>("default"); // ローカルステートで選択を管理

    // コンポーネントのマウント時に URL パラメータから sortOrder の初期値を設定
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sortParam = searchParams.get("order");
        if (sortParam) {
            setSortOrder(sortParam);
        }
    }, [location.search]);

    // イベントハンドラ：ローカルステートを更新
    const handleSortChange = (event: SelectChangeEvent<string>) => {
        setSortOrder(event.target.value);
        // URL パラメータを更新
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("order"); // 既存の 'sort' パラメータを削除
        searchParams.set("order", event.target.value); // 新しい 'sort' パラメータを追加
        window.location.href = `${location.pathname}?${searchParams.toString()}`;
    };

    return (
        <>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Sort</FormLabel>
                <FormGroup>
                    <Select
                        labelId="sort-order-label"
                        value={sortOrder} // ローカルステートを利用
                        onChange={handleSortChange}
                    >
                        <MenuItem value="default">Default</MenuItem>
                        <MenuItem value="newest">Newest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                    </Select>
                </FormGroup>
            </FormControl>
        </>
    );
};

export default SortSelector;
