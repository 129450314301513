import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import { BarChart2 } from "lucide-react";
import { Coffee } from "lucide-react";

import { useEffect, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Popover, List, Paper, useTheme } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import { SvgIconProps } from "@mui/material/SvgIcon";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import FunctionsIcon from "@mui/icons-material/Functions";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CoffeeIcon from "@mui/icons-material/Coffee";
import BookIcon from "@mui/icons-material/Book";
import InfoIcon from "@mui/icons-material/Info";
import { fetchUserAttributes, signOut } from "@aws-amplify/auth";
import UserAuthMenu from "../molecules/UserAuthMenu";

interface Service {
    name: string;
    icon: React.ComponentType<SvgIconProps>;
    url: string;
}

const pages_grid: Service[] = [
    { name: "Search", icon: SearchIcon, url: "/" },
    { name: "Journals", icon: LibraryBooksIcon, url: "/selected-journals" },
    { name: "Devices", icon: HandymanIcon, url: "/primary-devices" },
    { name: "Glossary", icon: LocalLibraryIcon, url: "/glossary" },
    { name: "Equation", icon: FunctionsIcon, url: "/tex-equation" },
    { name: "About (JA)", icon: InfoIcon, url: "/about" },
    { name: "Chat Bot(Beta)", icon: SmartToyIcon, url: "https://fusionpapers-chatbot.vercel.app" },
    { name: "Blog (JA)", icon: BookIcon, url: "https://scrapbox.io/fusionpapers/" },
    { name: "Buy me a coffee", icon: CoffeeIcon, url: "https://buymeacoffee.com/m.fukuyama/paperwithfusion" },
];

const SearchResultsHeader: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    const pathname = window.location.pathname;
    const tbm = pathname.split("/").pop();

    const handleSearch = () => {
        if (selectedOption !== null && selectedOption.length > 1) {
            navigate(`/search?query=${selectedOption}&order=newest`);
        }
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>("U");
    const theme = useTheme();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await signOut();
            setIsLoggedIn(false);
            navigate("/");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        const authenticateAndGetSubId = async () => {
            setIsLoading(true);
            try {
                const currentUserAttributes = await fetchUserAttributes();
                const attributes = currentUserAttributes;
                const picture = attributes.picture;
                const username = attributes.name;
                setIsLoggedIn(true);
                setUsername(username ?? "U");
                setImageUrl(picture ?? null);
            } catch (error) {
                setIsLoggedIn(false);
            } finally {
                setIsLoading(false);
            }
        };
        authenticateAndGetSubId();
    }, []);

    return (
        <header className="w-full bg-white border-b border-gray-200 text-sm py-3 sm:py-0 lg:py-6">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <nav className="relative max-w-[100rem] w-full px-4 sm:px-6 md:px-6 lg:px-32" aria-label="Global">
                        {/* Mobile layout */}
                        <Box className="flex justify-between items-center md:hidden">
                            <a href="/" aria-label="Brand" style={{ display: "inline-block" }}>
                                <img alt="Logo" src="/main_logo.jpg" style={{ width: "150px" }} />
                            </a>
                            <Box className="flex items-center">
                                <IconButton
                                    onClick={handleClick}
                                    size="large"
                                    aria-label="show services"
                                    color="inherit"
                                >
                                    <AppsIcon />
                                </IconButton>
                                <UserAuthMenu
                                    isLoading={isLoading}
                                    isLoggedIn={isLoggedIn}
                                    imageUrl={imageUrl}
                                    username={username}
                                    theme={theme}
                                    onLogout={handleLogout}
                                />
                            </Box>
                        </Box>

                        {/* Desktop layout */}
                        <Box className="hidden md:flex md:items-center md:justify-between">
                            <Box className="flex items-center flex-grow">
                                <a href="/" aria-label="Brand" className="mr-4" style={{ display: "inline-block" }}>
                                    <img alt="Logo" src="/main_logo.jpg" style={{ width: "200px" }} />
                                </a>
                                <Box className="flex-grow max-w-3xl">
                                    <Stack spacing={2} className="w-full">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Autocomplete
                                                id="free-solo-demo"
                                                freeSolo
                                                options={fusion_key_words.map((option) => option.title)}
                                                onInputChange={(event, newInputValue) => {
                                                    setSelectedOption(newInputValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        onKeyDown={(event) => {
                                                            if (
                                                                event.key === "Enter" &&
                                                                selectedOption !== null &&
                                                                selectedOption.length > 1
                                                            ) {
                                                                handleSearch();
                                                            }
                                                        }}
                                                        sx={{
                                                            width: "100%",
                                                            height: "40px",
                                                            borderRadius: "32px",
                                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: "32px",
                                                            },
                                                        }}
                                                    />
                                                )}
                                                defaultValue={query}
                                                className="flex-grow"
                                            />
                                            <Tooltip
                                                title={
                                                    selectedOption === null || selectedOption.length <= 1
                                                        ? "Please enter at least two characters"
                                                        : ""
                                                }
                                            >
                                                <span>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleSearch}
                                                        disabled={selectedOption === null || selectedOption.length <= 1}
                                                        sx={{
                                                            height: "40px",
                                                            borderRadius: "32px",
                                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: "32px",
                                                            },
                                                        }}
                                                    >
                                                        <SearchIcon className="icon" /> search
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Stack>
                                        <Stack spacing={2} direction="row">
                                            {tbm === "analyze" ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={handleSearch}
                                                    disabled={selectedOption === null || selectedOption.length <= 1}
                                                >
                                                    <ClearIcon
                                                        className="icon"
                                                        fontSize="small"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Analyze
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (selectedOption !== null)
                                                            navigate(`/analyze?query=${selectedOption}`);
                                                    }}
                                                    disabled={selectedOption === null || selectedOption.length <= 1}
                                                    sx={{
                                                        height: "40px",
                                                        borderRadius: "32px",
                                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "32px",
                                                        },
                                                    }}
                                                >
                                                    <BarChart2
                                                        className="w-4 text-blue-600"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Analyze
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    if (selectedOption !== null && selectedOption.length > 1) {
                                                        window.location.href = `https://www.buymeacoffee.com/m.fukuyama/paperwithfusion`;
                                                    }
                                                }}
                                                disabled={selectedOption === null || selectedOption.length <= 1}
                                                sx={{
                                                    height: "40px",
                                                    borderRadius: "32px",
                                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                    "& .MuiOutlinedInput-root": {
                                                        borderRadius: "32px",
                                                    },
                                                }}
                                            >
                                                <Coffee className="w-4 text-blue-600" style={{ marginRight: "5px" }} />
                                                buy me a coffee
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box className="flex items-center ml-4">
                                <IconButton
                                    onClick={handleClick}
                                    size="large"
                                    aria-label="show services"
                                    color="inherit"
                                >
                                    <AppsIcon />
                                </IconButton>
                                <UserAuthMenu
                                    isLoading={isLoading}
                                    isLoggedIn={isLoggedIn}
                                    imageUrl={imageUrl}
                                    username={username}
                                    theme={theme}
                                    onLogout={handleLogout}
                                />
                            </Box>
                        </Box>

                        {/* Common Popover for both layouts */}
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <List sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 1, p: 2 }}>
                                {pages_grid.map((service) => (
                                    <Grid key={service.name} item>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                p: 1,
                                                cursor: "pointer",
                                                "&:hover": {
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                },
                                            }}
                                            component="a"
                                            href={service.url}
                                        >
                                            <service.icon sx={{ fontSize: 40, mb: 1 }} />
                                            <Typography variant="caption" align="center">
                                                {service.name}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </List>
                        </Popover>
                    </nav>

                    {/* Mobile search bar */}
                    <Box className="md:hidden mt-4 px-4">
                        <Stack spacing={2} className="w-full">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Autocomplete
                                    id="free-solo-demo-mobile"
                                    freeSolo
                                    options={fusion_key_words.map((option) => option.title)}
                                    onInputChange={(event, newInputValue) => {
                                        setSelectedOption(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            onKeyDown={(event) => {
                                                if (
                                                    event.key === "Enter" &&
                                                    selectedOption !== null &&
                                                    selectedOption.length > 1
                                                ) {
                                                    handleSearch();
                                                }
                                            }}
                                            sx={{
                                                width: "100%",
                                                height: "40px",
                                                borderRadius: "32px",
                                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "32px",
                                                },
                                            }}
                                        />
                                    )}
                                    defaultValue={query}
                                    className="flex-grow"
                                />
                                <Tooltip
                                    title={
                                        selectedOption === null || selectedOption.length <= 1
                                            ? "Please enter at least two characters"
                                            : ""
                                    }
                                >
                                    <span>
                                        <Button
                                            variant="outlined"
                                            onClick={handleSearch}
                                            disabled={selectedOption === null || selectedOption.length <= 1}
                                            sx={{
                                                height: "40px",
                                                borderRadius: "32px",
                                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "32px",
                                                },
                                            }}
                                        >
                                            <SearchIcon className="icon" /> search
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Stack>
                            <Stack spacing={2} direction="row" justifyContent="center">
                                {tbm === "analyze" ? (
                                    <Button
                                        variant="contained"
                                        onClick={handleSearch}
                                        disabled={selectedOption === null || selectedOption.length <= 1}
                                    >
                                        <ClearIcon className="icon" fontSize="small" style={{ marginRight: "5px" }} />
                                        Analyze
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            if (selectedOption !== null) navigate(`/analyze?query=${selectedOption}`);
                                        }}
                                        disabled={selectedOption === null || selectedOption.length <= 1}
                                        sx={{
                                            height: "40px",
                                            borderRadius: "32px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "32px",
                                            },
                                        }}
                                    >
                                        <BarChart2 className="w-4 text-blue-600" style={{ marginRight: "5px" }} />
                                        Analyze
                                    </Button>
                                )}
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (selectedOption !== null && selectedOption.length > 1) {
                                            window.location.href = `https://www.buymeacoffee.com/m.fukuyama/paperwithfusion`;
                                        }
                                    }}
                                    disabled={selectedOption === null || selectedOption.length <= 1}
                                    sx={{
                                        height: "40px",
                                        borderRadius: "32px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "32px",
                                        },
                                    }}
                                >
                                    <Coffee className="w-4 text-blue-600" style={{ marginRight: "5px" }} />
                                    buy me a coffee
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </header>
    );
};

const fusion_key_words = [
    { title: "JET" },
    { title: "DIII-D" },
    { title: "ITER" },
    { title: "ASDEX Upgrade" },
    { title: "JT-60SA" },
    { title: "EAST" },
    { title: "Wendelstein 7-X" },
    { title: "LHD" },
    { title: "KSTAR" },
    { title: "TEXTOR" },
    { title: "W7-X" },
    { title: "CHS" },
    { title: "HL-2A tokamak" },
    { title: "TFTR" },
    { title: "JFT-2" },
    { title: "JIPPT-IIU" },
    { title: "JT-60" },
    { title: "LH" },
    { title: "TJ-II stellarator" },
    { title: "Alcator C-Mod" },
    { title: "TFR" },
    { title: "JET-ILW" },
    { title: "CFETR" },
    { title: "TJ-II" },
    { title: "NSTX-U" },
    { title: "FTU" },
    { title: "HL-2A" },
    { title: "ASDEX" },
    { title: "ADITYA" },
];

export default SearchResultsHeader;
